import React, { useState } from 'react'
import { useTranslation } from 'next-i18next'
import { UnprocessableContentError } from 'shared/errors/unprocessable-content-error'
import { BadRequest } from '../errors/bad-request'
import { InternalError } from '../errors/internal-error'
import { SecondaryButton } from './button'
import Modal from './modal'
import PrimaryButton from './primary-button'

type ConfirmationProps = {
  onCloseModal: () => void
  onConfirm: () => void
  title?: string
  confirmationContent?: React.ReactNode
  errorMessageMap?: Partial<Record<'unprocessableContentError' | 'internalError', string>>
}

function Confirmation({
  onCloseModal,
  onConfirm,
  title = 'confirmation_modal.default_title',
  confirmationContent = '',
  errorMessageMap,
}: ConfirmationProps) {
  const { t } = useTranslation()
  const [opened, setOpened] = useState(true)
  const [error, setError] = useState('')
  const [isLoading, setIsLoading] = useState(false)

  function closeModal() {
    setOpened(false)
    onCloseModal()
  }

  const handleConfirm = async () => {
    try {
      setIsLoading(true)
      await onConfirm()
      setOpened(false)
    } catch (e) {
      if (e instanceof BadRequest && e.errors.common) {
        setError(e.errors.common.join(''))
      } else if (e instanceof UnprocessableContentError) {
        setError(t(errorMessageMap?.['unprocessableContentError'] || ''))
      } else if (e instanceof InternalError) {
        setError(t(errorMessageMap?.['internalError'] || 'core.error.internal_error_message'))
      }
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <>
      <Modal opened={opened} onClose={closeModal} title={t(title)} small>
        <div className="mb-6 flex justify-center">{confirmationContent}</div>
        <div className="mb-6 flex justify-center gap-4">
          <PrimaryButton className="ml-5 p-1 px-7" onClick={handleConfirm} isLoading={isLoading}>
            {t('confirmation_modal.confirm').toUpperCase()}
          </PrimaryButton>
          <SecondaryButton className="px-7" onClick={onCloseModal}>
            {t('confirmation_modal.cancel').toUpperCase()}
          </SecondaryButton>
        </div>
        {error && <p className="mt-2 text-center text-sm text-red">{error}</p>}
      </Modal>
    </>
  )
}

export default Confirmation
