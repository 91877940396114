import { PropsWithChildren } from 'react'
import { useRouter } from 'next/router'
import LinkWithoutPrefetch from 'shared/components/link-without-prefetch'
import { isCustomDomain } from 'shared/types/is-custom-domain'
import { useAvailableSections } from '../hooks/use-available-sections'
import { AvailableSectionsInterface } from '../types/available-sections-interface'

interface LogoLinkProps extends PropsWithChildren {
  dashboardLink: string
  logoSrc: string | undefined
  shouldRenderDashboardLink: boolean
}

const getLogoUrl = (route: string, availableSections?: AvailableSectionsInterface) => {
  if (availableSections?.communities && availableSections.courses) {
    return route.includes('school') ? '/school' : '/community'
  }

  if (availableSections === undefined) return undefined

  if (
    availableSections?.courses ||
    (availableSections?.communities === undefined && availableSections?.courses === undefined)
  )
    return '/school'

  if (availableSections?.communities) return '/community'
}

const LogoLink = ({
  children,
  dashboardLink,
  logoSrc,
  shouldRenderDashboardLink,
}: LogoLinkProps) => {
  const router = useRouter()
  const shouldRenderBasic = shouldRenderDashboardLink && !isCustomDomain()
  const { data: availableSections } = useAvailableSections()
  const logoUrl = getLogoUrl(router.route, availableSections)

  if (shouldRenderBasic) {
    return (
      <a
        className={(!shouldRenderDashboardLink && 'pointer-events-none') || ''}
        href={dashboardLink}
      >
        {children}
      </a>
    )
  }

  if (logoUrl === undefined) {
    return <img src={logoSrc} alt="Systeme.io" className="max-h-[35px]" />
  }

  return <LinkWithoutPrefetch href={logoUrl}>{children}</LinkWithoutPrefetch>
}

export default LogoLink
